import * as ko from "knockout";
import * as common from "../_common";
import {observable} from "knockout-decorators";
import {App} from "../../../app";
import {UserRankingsDto} from "../../../api/generated";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import {rankingApi} from "../../../api/api-wrapper";
import "../../../components/elements/ranking/card-rankings";
import globalState from '../../../global-state';

/**
 * Ranking types.
 */
export enum RankingType {
    Ideas = <any>'ideas',
    IdeasRecent = <any>'ideas_recent',
    Activities = <any>'activities',
    ActivitiesRecent = <any>'activities_recent'
}

/**
 * Header colors for rankings.
 */
export enum RankingColor {
    Primary = <any>'primary',
    Secondary = <any>'secondary'
}


/**
 * Display parameters for widget.
 */
interface WidgetRankingViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Component view model. A stub only.
 */
class WidgetRankingViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentRanking> {

    public rankings: KnockoutObservable<UserRankingsDto>;

    constructor(ctx: WidgetRankingViewModelParams) {
        super(ctx);

        if (typeof this.widgetContent.headline === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "headline");
            (<common.WidgetContentRanking>ctx.widget.content).headline = null;
        }
        if (typeof this.widgetContent.ranking === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "ranking");
            (<common.WidgetContentRanking>ctx.widget.content).ranking = null;
        }
        if (typeof this.widgetContent.color === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "color");
            (<common.WidgetContentRanking>ctx.widget.content).color = null;
        }
        if (typeof this.widgetContent.limit === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "limit");
            (<common.WidgetContentRanking>ctx.widget.content).limit = null;
        }

        this.rankings = asyncComputed(async () => {
            let rankingPromise: Promise<UserRankingsDto>;

            // Choose which ranking should be loaded according to the selected ranking type
            if (this.widgetContent.ranking == RankingType.Ideas) {
                rankingPromise = rankingApi.getUserRankingPoints(this.widgetContent.limit);
            } else if (this.widgetContent.ranking == RankingType.Ideas) {
                rankingPromise = rankingApi.getUserRankingIdeasRecent(this.widgetContent.limit);
            } else if (this.widgetContent.ranking == RankingType.Activities) {
                rankingPromise = rankingApi.getUserRankingPoints(this.widgetContent.limit);
            } else if (this.widgetContent.ranking == RankingType.ActivitiesRecent) {
                rankingPromise = rankingApi.getUserRankingPointsRecent(this.widgetContent.limit);
            } else {
                rankingPromise = Promise.resolve(null);
            }

            return rankingPromise.catch(err => {
                console.error("widget ranking could not be loaded", this.widgetContent.ranking, err);
                return null;
            });
        }, null);
    }

    public editComponent() {
        return "widget-ranking-edit";
    }

}

let componentWidgetRichtext: KnockoutComponentTypes.Config = {
    viewModel: (params: WidgetRankingViewModelParams) => new WidgetRankingViewModel(params),
    template: <string>require('./widget-ranking.html')
};

ko.components.register("widget-ranking", componentWidgetRichtext);


/**
 * Editor parameters for widget.
 */
interface WidgetRankingEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model. Currently a stub only.
 */
class WidgetRankingEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentRanking> {

    @observable({expose: true, deep: false})
    public headline: string;

    @observable({expose: true, deep: false})
    public ranking: RankingType;

    @observable({expose: true, deep: false})
    public color: RankingColor;

    @observable({expose: true, deep: false})
    public limit: number;

    constructor(ctx: WidgetRankingEditViewModelParams) {
        super(ctx);
        this.headline = this.widgetContent.headline;
        this.ranking = this.widgetContent.ranking || RankingType.IdeasRecent;
        this.color = this.widgetContent.color || RankingColor.Primary;
        this.limit = this.widgetContent.limit || globalState.appVars.rankingsLimit || 10;
        ctx.callback.getResolveData = () => {
            this.widgetContent.headline = this.headline;
            this.widgetContent.ranking = this.ranking;
            this.widgetContent.color = this.color;
            this.widgetContent.limit = this.limit || globalState.appVars.rankingsLimit || 10;
            return Promise.resolve();
        }
    }

    /**
     * Get the options for the ranking type.
     */
    public rankingOptions() {
        return App.enumOptions(RankingType, "widget.ranking.modal.rankingOptions.");
    }

    /**
     * Get the options for the header color.
     */
    public colorOptions() {
        return App.enumOptions(RankingColor, "widget.ranking.modal.colorOptions.");
    }
}


let componentWidgetRichtextEdit: KnockoutComponentTypes.Config = {
    viewModel: (params: WidgetRankingEditViewModelParams) => new WidgetRankingEditViewModel(params),
    template: <string>require('./widget-ranking-edit.html')
};

ko.components.register("widget-ranking-edit", componentWidgetRichtextEdit);
