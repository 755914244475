import * as ko from "knockout";
import * as common from "../_common";
import {FileData} from "../_common";
import {observable} from "knockout-decorators";
import {postbox} from "../../../components/util/postbox";
import globalState from "../../../global-state";
import {contentApi} from "../../../api/api-wrapper";
import i18nextko from "../../../bindings/i18nko";
import {config} from "../../../utils/clientConfigWrapper";

/**
 * Display parameters for widget.
 */
interface WidgetCiteViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Shows a cite as widget. TBD - only a stub at the moment.
 */
class WidgetCiteViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentCite> {

    public basePath: string = config.attachmentEndpoint;

    constructor(ctx: WidgetCiteViewModelParams) {
        super(ctx);

        if (typeof this.widgetContent.cite === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "cite");
            (<common.WidgetContentCite>ctx.widget.content).cite = null;
        }
        if (typeof this.widgetContent.name === 'undefined') {
            observable({deep: false, expose: true})(this.widgetContent, "name");
            (<common.WidgetContentCite>ctx.widget.content).name = null;
        }
        if (typeof this.widgetContent.imageRef === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "imageRef");
            (<common.WidgetContentCite>ctx.widget.content).imageRef = null;
        }
    }

    public editComponent() {
        return "widget-cite-edit";
    }

}

ko.components.register("widget-cite", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetCiteViewModelParams) => new WidgetCiteViewModel(params),
    template: <string>require('./widget-cite.html')
});

/**
 * Editor parameters for widget.
 */
interface WidgetCiteEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model. Currently a stub only.
 */
class WidgetCiteEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentCite> {

    @observable({expose: true, deep: true})
    public cite: string;

    @observable({expose: true, deep: true})
    public name: string;

    public imageData: KnockoutObservable<FileData>;

    constructor(ctx: WidgetCiteEditViewModelParams) {
        super(ctx);

        this.cite = this.widgetContent.cite;
        this.name = this.widgetContent.name;

        this.imageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });

        ctx.callback.getResolveData = () => {
            this.widgetContent.cite = this.cite;
            this.widgetContent.name = this.name;

            if (this.imageData() && this.imageData().base64String()) {
                if (this.imageData().file().type.search(/^image\/.*$/) == -1) {
                    postbox.addError('widget.cite.error.invalidType');

                    return Promise.reject();
                }

                globalState.loading(true);

                // close modal immediately
                window.setTimeout(() => {
                    contentApi
                        .postUploads([
                            {
                                filename: this.imageData().file().name,
                                mainImage: false,
                                mimeType: this.imageData().file().type,
                                data: this.imageData().base64String()
                            }
                        ])
                        .then(paths => {
                            this.widgetContent.imageRef = paths[0];
                            this.widgetContent.mimetype = this.imageData().file().type;
                        })
                        .catch(err => console.error(err))
                        .finally(() => globalState.loading(false));
                });
            }

            return Promise.resolve();
        }
    }

    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }
}

ko.components.register("widget-cite-edit", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetCiteEditViewModelParams) => new WidgetCiteEditViewModel(params),
    template: <string>require('./widget-cite-edit.html')
});
