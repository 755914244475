import * as ko from "knockout";
import * as common from "../_common";
import {observable} from "knockout-decorators";


/**
 * Display parameters for widget.
 */
interface WidgetEmptyViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Shows an image. Only a stub at the moment.
 */
class WidgetEmptyViewModel extends common.WidgetComponentCompositionModel<common.WidgetContent> {

    public setWidgetType:Function;

    constructor(ctx: WidgetEmptyViewModelParams) {
        super(ctx);
        this.setWidgetType = ctx.setWidgetType;
    }

    public editComponent() {
        return null;
    }

}

let componentWidgetEmpty: KnockoutComponentTypes.Config = {
    viewModel: (params: WidgetEmptyViewModelParams, componentInfo?: KnockoutComponentTypes.ComponentInfo) => new WidgetEmptyViewModel(params),
    template: <string> require('./widget-empty.html')
};

ko.components.register("widget-empty", componentWidgetEmpty);

