import * as ko from "knockout";
import * as common from "../_common";
import {FileData} from "../_common";
import {autobind, computed, observable} from "knockout-decorators";
import * as modal from "../../../components/elements/modal/modal";
import i18nextko from "../../../bindings/i18nko";
import {WidgetDto} from "../../../api/generated";
import {postbox} from "../../../components/util/postbox";
import globalState from "../../../global-state";
import {contentApi} from "../../../api/api-wrapper";
import {config} from "../../../utils/clientConfigWrapper";
import moment = require("moment");


/**
 * Display parameters for widget.
 */
interface WidgetCampaignViewModelParams extends common.WidgetComponentCompositionContext {
    save: Function;
    delete: Function;
    imageVisible: boolean;
}

/**
 * This widget shows information about a campaign. TBD - this is currently a stub. Could be a page
 * with some additional parameters.
 */
class WidgetCampaignViewModel extends common.WidgetComponentModel<common.WidgetContentCampaign> {

    public basePath: string = `${config.attachmentEndpoint}${config.campaignsUploadPath}/`;

    public editing = ko.observable(false);
    public save: Function;
    public deleteWidget: Function;
    public imageVisible: boolean;

    constructor(params: WidgetCampaignViewModelParams) {
        super(params.widget);
        this.save = params.save;
        this.deleteWidget = params.delete;
        this.imageVisible = params.imageVisible != false;

        if (typeof this.widgetContent.headline === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "headline");
            (<common.WidgetContentCampaign>params.widget.content).headline = null;
        }
        if (typeof this.widgetContent.description === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "description");
            (<common.WidgetContentCampaign>params.widget.content).description = null;
        }
        if (typeof this.widgetContent.start === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "start");
            (<common.WidgetContentCampaign>params.widget.content).start = null;
        }
        if (typeof this.widgetContent.end === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "end");
            (<common.WidgetContentCampaign>params.widget.content).end = null;
        }
        if (typeof this.widgetContent.teaserImageRef === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "teaserImageRef");
            (<common.WidgetContentCampaign>params.widget.content).teaserImageRef = null;
        }
        if (typeof this.widgetContent.imageRef === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "imageRef");
            (<common.WidgetContentCampaign>params.widget.content).imageRef = null;
        }
    }

    @computed
    public get campaignEnd() {
        return this.widgetContent.end ?
            (moment(this.widgetContent.end).isSameOrAfter(moment(), 'day') ?
                moment(this.widgetContent.end).fromNow() :
                i18nextko.t('widget.campaigns.endedSince', {
                    'since': moment(this.widgetContent.end).fromNow()
                })) :
            i18nextko.t('widget.campaigns.ended');
    }

    @autobind
    public edit() {
        if (!this.editing()) {

            return modal
                .createModal({
                    headerLabel: "",
                    closeLabel: i18nextko.t('widget.modal.button.close'),
                    confirmLabel: i18nextko.i18n.t("widget.modal.button.confirm"),
                    modalBodyComponent: "widget-campaign-edit",
                    params: {
                        widget: this.widget
                    }
                })
                .then(() => this.editing(true))
                .catch(() => Promise.resolve());

        } else {
            this.editing(false);

            return Promise.resolve();
        }
    }
}

ko.components.register("widget-campaign", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetCampaignViewModelParams) => new WidgetCampaignViewModel(params),
    template: <string>require('./widget-campaign.html')
});

/**
 * Editor parameters.
 */
interface WidgetCampaignEditViewModelParams extends common.WidgetComponentEditContext {
    params: {
        widget: WidgetDto;
        editing: KnockoutObservable<boolean>;
    };
}

class WidgetCampaignEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentCampaign> {

    @observable({expose: true, deep: true})
    public headline: string;

    @observable({expose: true, deep: true})
    public description: string;

    @observable({deep: false, expose: true})
    public start: Date;

    @observable({deep: false, expose: true})
    public end: Date;

    public imageData: KnockoutObservable<FileData>;
    public teaserImageData: KnockoutObservable<FileData>;

    constructor(ctx: WidgetCampaignEditViewModelParams) {
        super(ctx);

        this.imageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });

        this.teaserImageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });

        this.headline = this.widgetContent.headline;
        this.description = this.widgetContent.description;
        this.start = this.widgetContent.start || new Date();
        this.end = this.widgetContent.end || new Date();

        ctx.callback.getResolveData = () => {
            this.widgetContent.headline = this.headline;
            this.widgetContent.description = this.description;
            this.widgetContent.start = this.start;
            this.widgetContent.end = this.end;

            window.setTimeout(() => {
                globalState.loading(true);
                Promise
                    .all([
                        this.upload(this.imageData(), 'imageRef'),
                        this.upload(this.teaserImageData(), 'teaserImageRef')
                    ])
                    .catch(err => console.error(err))
                    .finally(() => globalState.loading(false));
            });

            return Promise.resolve();
        }
    }

    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }

    private upload(imageData: FileData, imageProperty: string): Promise<void> {
        if (imageData && imageData.base64String()) {
            if (imageData.file().type.search(/^image\/.*$/) == -1) {
                postbox.addError('widget.image.error.invalidType');

                return Promise.reject();
            }

            return contentApi
                .postUploads([
                    {
                        filename: imageData.file().name,
                        mainImage: false,
                        mimeType: imageData.file().type,
                        data: imageData.base64String()
                    }
                ], config.campaignsUploadPath)
                .then(() => {
                    this.widgetContent[imageProperty] = imageData.file().name;
                    this.widgetContent[imageProperty + 'Mimetype'] = imageData.file().type;
                })

        } else {
            return Promise.resolve();
        }
    }

    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }
}

ko.components.register("widget-campaign-edit", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetCampaignEditViewModelParams) => new WidgetCampaignEditViewModel(params),
    template: <string>require('./widget-campaign-edit.html')
});
