import "../editor/widget-news/widget-news";
import "../editor/widget-campaign/widget-campaign";
import "../editor/widget-cite/widget-cite";
import "../editor/widget-countdown/widget-countdown";
import "../editor/widget-gallery/widget-gallery";
import "../editor/widget-idea/widget-idea";
import "../editor/widget-campaign-teaser/widget-campaign-teaser";
import "../editor/widget-image/widget-image";
import "../editor/widget-page/widget-page";
import "../editor/widget-ranking/widget-ranking";
import "../editor/widget-richtext/widget-richtext";
import "../editor/widget-video/widget-video";
import "../editor/widget-dashboard-todos/widget-dashboard-todos";
import "../editor/widget-empty/widget-empty";

import globalState from "../../global-state";
import {contentApi} from "../../api/api-wrapper";
import {postbox} from "../../components/util/postbox";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {WidgetDto} from "../../api/generated";
import {createConfirmModal} from "../../components/elements/modal/modal";
import i18nextko from "../../bindings/i18nko";

export class EditorViewModelContext extends Context {
    widget: WidgetDto;
    params: {
        id: number;
    };
}

export function saveWidget(widget: WidgetDto) {
    globalState.loading(true);

    const savePromise = widget.id ? contentApi.putWidget(widget.id, widget) : contentApi.postWidget(widget);
    return savePromise.then(savedWidget => {
            postbox.addSuccess('editor.success.submit');

            return Promise.resolve(savedWidget);
        })
        .catch(err => {
            postbox.addError('editor.error.submit');

            return Promise.reject(err);
        })
        .finally(() => globalState.loading(false));
}

export function deleteWidget(widget: WidgetDto) {
    return createConfirmModal(
        i18nextko.t("editor.modal.delete.body"),
        i18nextko.t("editor.modal.delete.header"),
        i18nextko.t("global.delete"),
        i18nextko.t("global.cancel")
    )
        .then(() => {
            globalState.loading(true);

            return contentApi.deleteWidget(widget.id).then(
                () => {
                    postbox.addSuccess('editor.success.delete');

                    return Promise.resolve();
                })
                .catch(err => {
                    postbox.addError('editor.error.delete');

                    return Promise.reject(err);
                })
                .finally(() => globalState.loading(false));
        });
}

export function loadWidget(id: number): Promise<WidgetDto> {
    return contentApi.getWidget(id)
        .then(widget => {
            if (!(widget.type === WidgetDto.TypeEnum.PAGE || widget.type === WidgetDto.TypeEnum.NEWS)) {
                return Promise.reject("invalid root widget type");
            }

            return widget;
        });
}

