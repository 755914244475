import * as ko from "knockout";
import * as common from "../_common";
import {FileData} from "../_common";
import {observable} from "knockout-decorators";
import {contentApi} from "../../../api/api-wrapper";
import i18nextko from "../../../bindings/i18nko";
import globalState from '../../../global-state';
import {postbox} from "../../../components/util/postbox";
import {config} from "../../../utils/clientConfigWrapper";


/**
 * Display parameters for widget.
 */
interface WidgetVideoViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Shows a video. TBD - only a stub at the moment.
 */
class WidgetVideoViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentVideo> {

    public basePath: string = config.attachmentEndpoint;

    constructor(ctx: WidgetVideoViewModelParams) {
        super(ctx);
        if (typeof this.widgetContent.ref === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "ref");
            (<common.WidgetContentVideo>ctx.widget.content).ref = null;
        }
        if (typeof this.widgetContent.mimetype === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "mimetype");
            (<common.WidgetContentVideo>ctx.widget.content).mimetype = null;
        }
    }

    public editComponent() {
        return "widget-video-edit";
    }
}

ko.components.register("widget-video", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetVideoViewModelParams) => new WidgetVideoViewModel(params),
    template: <string>require('./widget-video.html')
});


/**
 * Editor parameters for widget.
 */
interface WidgetVideoEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model.
 */
class WidgetVideoEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentVideo> {

    public videoData: KnockoutObservable<FileData>;

    constructor(ctx: WidgetVideoEditViewModelParams) {
        super(ctx);
        this.videoData = ko.observable({
            file: ko.observable()
        });

        ctx.callback.getResolveData = () => {
            if (this.videoData() && this.videoData().file()) {
                if (this.videoData().file().type.search(/^video\/mp4$/) == -1) {
                    postbox.addError('widget.video.error.invalidType');

                    return Promise.reject();
                }

                globalState.loading(true)

                // close modal immediately
                window.setTimeout(() => {
                    contentApi.postFormUpload(this.videoData().file())
                        .then(path => {
                            this.widgetContent.ref = path;
                            this.widgetContent.mimetype = this.videoData().file().type;
                        })
                        .catch(err => console.error(err))
                        .finally(() => globalState.loading(false));
                }, 1);
            }

            return Promise.resolve();
        }
    }

    public videoFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }
}

ko.components.register("widget-video-edit", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetVideoEditViewModelParams) => new WidgetVideoEditViewModel(params),
    template: <string>require('./widget-video-edit.html')
});
