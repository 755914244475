import {WidgetDto} from "../../api/generated";
import {contentApi} from "../../api/api-wrapper";
import {Context} from "@profiscience/knockout-contrib-router";


/**
 * Editor list context.
 */
class ViewModelContext extends Context {
    widgets: WidgetDto[];
}


/**
 * Shows a list of pages without a parent for editing.
 */
class ViewModel {
    public widgets: WidgetDto[];

    constructor(ctx: ViewModelContext) {
        this.widgets = ctx.widgets;
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./editorList.html'),
    componentName: "editor-list",
    loader: (ctx: ViewModelContext) => {
        return contentApi.getWidgets().then((widgets) => ctx.widgets = widgets);
    }
};
