import * as ko from "knockout";
import * as common from "../_common";
import {EvaluationDto, IdeaDto, UserDto} from "../../../api/generated";
import {userApi} from "../../../api/api-wrapper";
import "../../../components/elements/ranking/card-rankings";
import globalState from '../../../global-state';
import {asyncComputed} from "knockout-async-computed";
import i18nextko from "../../../bindings/i18nko";
import {isImplementationDescriptionMissing} from "../../evaluation/evaluationUtils";
import {isIdeaActive} from "../../ideas/ideaUtils";
import {config} from "../../../utils/clientConfigWrapper";
import moment = require("moment");

/**
 * Display parameters for widget.
 */
interface WidgetDashboardTodosViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Component view model. This widget has no edit.
 */
class WidgetDashboardTodosViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentDashboardTodos> {

    /**
     * The currently logged in user.
     */
    public user: KnockoutObservable<UserDto>;

    /**
     * The user's evaluations.
     */
    public evaluations: KnockoutObservable<EvaluationDto[]>;

    /**
     * The user's ideas.
     */
    public ideas: KnockoutObservable<IdeaDto[]>;

    /**
     * The notification text displayed to the user.
     */
    public todos: KnockoutComputed<string[]>;


    constructor(ctx: WidgetDashboardTodosViewModelParams) {
        super(ctx);

        this.user = globalState.user;

        this.ideas = asyncComputed(async () =>
            userApi.getUserIdeas(this.user().id)
                .then(ideas =>
                    ideas.filter(idea =>
                        isIdeaActive(idea) && idea.created))
                .catch(err => {
                    console.error("ideas could not be loaded", err);
                    return [];
                }), []);

        this.evaluations = asyncComputed(async () =>
            userApi.getExpertEvaluations(this.user().id)
                .then(evaluations =>
                    evaluations.filter(evaluation =>
                        isIdeaActive(evaluation.idea) && evaluation.created))
                .catch(err => {
                    console.error("evaluations could not be loaded", err);
                    return [];
                }), []);

        this.todos = ko.pureComputed(() => {
            const todoList: string[] = [];

            // counters for the different to do's
            const unsubmittedIdeas = this.ideas().filter(idea =>
                idea.state === IdeaDto.StateEnum.Submission).length;
            const unsubmittedEvaluations = this.evaluations().filter(evaluation =>
                !evaluation.submittedTimestamp).length;
            const missingImplementationDescriptions = this.evaluations().filter(evaluation =>
                isImplementationDescriptionMissing(evaluation)).length;

            console.log("unsubmittedIdeas", unsubmittedIdeas, "unsubmittedEvaluations", unsubmittedEvaluations,
                "unassessedEvaluations", "missingImplementationDescriptions", missingImplementationDescriptions);

            // To do messages.
            if (unsubmittedIdeas > 0 || unsubmittedEvaluations > 0 || missingImplementationDescriptions > 0) {

                if (unsubmittedIdeas > 0) {
                    const key = unsubmittedIdeas > 1 ? "widget.dashboardTodos.unsubmittedIdeas" :
                        "widget.dashboardTodos.unsubmittedIdea";
                    todoList.push(i18nextko.i18n.t(key, {"count": unsubmittedIdeas}));
                }

                if (unsubmittedEvaluations > 0) {
                    const key = unsubmittedEvaluations > 1 ? "widget.dashboardTodos.unsubmittedEvaluations" :
                        "widget.dashboardTodos.unsubmittedEvaluation";
                    todoList.push(i18nextko.i18n.t(key, {"count": unsubmittedEvaluations}));
                }

                if (missingImplementationDescriptions > 0) {
                    const key = missingImplementationDescriptions > 1 ? "widget.dashboardTodos.missingImplementationDescriptions" :
                        "widget.dashboardTodos.missingImplementationDescription";
                    todoList.push(i18nextko.i18n.t(key, {"count": missingImplementationDescriptions}))
                }

            }

            return todoList;
        });

    }

    public editComponent() {
        return "widget-dashboard-todos-edit";
    }

}

let componentWidgetDashboardTodos: KnockoutComponentTypes.Config = {
    viewModel: (params: WidgetDashboardTodosViewModelParams) => new WidgetDashboardTodosViewModel(params),
    template: <string>require('./widget-dashboard-todos.html')
};

ko.components.register("widget-dashboard-todos", componentWidgetDashboardTodos);
