import * as ko from "knockout";
import * as common from "../_common";
import {observable} from "knockout-decorators";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import {ideaApi} from "../../../api/api-wrapper";
import {IdeaDto} from "../../../api/generated";

/**
 * Shows information about an idea. TBD - at the moment it is only a stub.
 */

/**
 * Display parameters for widget.
 */
interface WidgetIdeaViewModelParams extends common.WidgetComponentCompositionContext {

}

class WidgetIdeaViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentIdea> {

    public idea: KnockoutObservable<IdeaDto>;

    constructor(ctx: WidgetIdeaViewModelParams) {
        super(ctx);

        if (typeof this.widgetContent.ideaId === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "ideaId");
            (<common.WidgetContentIdea>ctx.widget.content).ideaId = null;
        }

        this.idea = asyncComputed(async () => {
            if (this.widgetContent.ideaId) {
                return ideaApi.getIdea(this.widgetContent.ideaId).catch(err => {
                    console.error("widget idea could not be loaded", this.widgetContent.ideaId, err);
                    return null;
                });

            } else {
                return Promise.resolve(null);
            }
        }, null);

    }

    public editComponent() {
        return "widget-idea-edit";
    }
}

ko.components.register("widget-idea", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetIdeaViewModelParams) => new WidgetIdeaViewModel(params),
    template: <string>require('./widget-idea.html')
});


/**
 * Editor parameters for widget.
 */
interface WidgetIdeaEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model. Currently a stub only.
 */
class WidgetIdeaEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentIdea> {

    @observable({expose: true, deep: true})
    public ideaId: number;

    constructor(ctx: WidgetIdeaEditViewModelParams) {
        super(ctx);
        this.ideaId = this.widgetContent.ideaId;

        ctx.callback.getResolveData = () => {
            this.widgetContent.ideaId = this.ideaId;
            return Promise.resolve();
        }
    }
}

ko.components.register("widget-idea-edit", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetIdeaEditViewModelParams) => new WidgetIdeaEditViewModel(params),
    template: <string>require('./widget-idea-edit.html')
});
