import {WidgetDto} from "../../../api/generated";
import * as ko from "knockout";
import * as common from "../_common";
import {FileData} from "../_common";
import {autobind, observable} from "knockout-decorators";
import "../widget-container/widget-container";
import * as modal from "../../../components/elements/modal/modal";
import i18nextko from "../../../bindings/i18nko";
import {postbox} from "../../../components/util/postbox";
import globalState from "../../../global-state";
import {contentApi} from "../../../api/api-wrapper";
import {config} from "../../../utils/clientConfigWrapper";

/**
 * Display parameters for widget.
 */
interface WidgetComponentNewsViewModelParams extends common.WidgetComponentCompositionContext {
    save: Function;
    delete: Function;
    teaserImageVisible: boolean;
}


/**
 * A page is the root entry point for showing widgets. Widget content contains
 * the layout to be used. Editing can be turned on an off for a page using its
 * editor. The widget is passed on to widget-container, was a page is implicitly
 * such an element.
 */
class WidgetComponentNewsViewModel extends common.WidgetComponentModel<common.WidgetContentNews> {

    public basePath: string = config.attachmentEndpoint;
    public editing = ko.observable(false);
    public save: Function;
    public deleteWidget: Function;
    public teaserImageVisible: boolean;

    constructor(params: WidgetComponentNewsViewModelParams) {
        super(params.widget);
        this.save = params.save;
        this.deleteWidget = params.delete;
        this.teaserImageVisible = params.teaserImageVisible != false;

        if (typeof this.widgetContent.headline === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "headline");
            (<common.WidgetContentNews>params.widget.content).headline = null;
        }
        if (typeof this.widgetContent.teaserText === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "teaserText");
            (<common.WidgetContentNews>params.widget.content).teaserText = null;
        }
        if (typeof this.widgetContent.teaserImageRef === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "teaserImageRef");
            (<common.WidgetContentNews>params.widget.content).teaserImageRef = null;
        }
        if (typeof this.widgetContent.publishDate === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "publishDate");
            (<common.WidgetContentNews>params.widget.content).publishDate = null;
        }
    }

    @autobind
    public edit() {
        if (!this.editing()) {

            return modal
                .createModal({
                    headerLabel: "",
                    closeLabel: i18nextko.t('widget.modal.button.close'),
                    confirmLabel: i18nextko.i18n.t("widget.modal.button.confirm"),
                    modalBodyComponent: "widget-news-edit",
                    params: {
                        widget: this.widget
                    }
                })
                .then(() => this.editing(true))
                .catch(() => Promise.resolve());

        } else {
            this.editing(false);

            return Promise.resolve();
        }
    }
}

ko.components.register("widget-news", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetComponentNewsViewModelParams) => new WidgetComponentNewsViewModel(params),
    template: <string>require('./widget-news.html')
});


/**
 * Editor parameters for widget.
 */
interface WidgetNewsEditViewModelParams extends common.WidgetComponentEditContext {
    params: {
        widget: WidgetDto;
        editing: KnockoutObservable<boolean>;
    };
}

/**
 * The page editor component allows to start and stop the edit mode for the page and
 * all child widgets. This will acutally flip the "editing" observable which is
 * passed down through all widget components. It is the widgets responsibility to
 * actually hide the elements not required for editing if the editor is not active.
 */
class WidgetNewsEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentNews> {

    public teaserImageData: KnockoutObservable<FileData>;

    @observable({expose: true, deep: true})
    public headline: string;

    @observable({expose: true, deep: true})
    public teaserText: string;

    @observable({deep: false, expose: true})
    public publishDate: Date;

    @observable({deep: false, expose: true})
    public visible: boolean;

    @observable({deep: false, expose: true})
    public pinned: boolean;

    constructor(ctx: WidgetNewsEditViewModelParams) {
        super(ctx);

        this.teaserImageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });

        this.headline = this.widgetContent.headline;
        this.teaserText = this.widgetContent.teaserText;
        this.publishDate = this.widgetContent.publishDate || new Date();
        this.visible = this.widgetContent.visible || true;
        this.pinned = this.widgetContent.pinned || false;

        ctx.callback.getResolveData = () => {
            this.widgetContent.headline = this.headline;
            this.widgetContent.teaserText = this.teaserText;
            this.widgetContent.publishDate = this.publishDate;
            this.widgetContent.visible = this.visible;
            this.widgetContent.pinned = this.pinned;

            if (this.teaserImageData() && this.teaserImageData().base64String()) {
                if (this.teaserImageData().file().type.search(/^image\/.*$/) == -1) {
                    postbox.addError('widget.image.error.invalidType');

                    return Promise.reject();
                }

                globalState.loading(true);

                // close modal immediately
                window.setTimeout(() => {
                    contentApi
                        .postUploads([
                            {
                                filename: this.teaserImageData().file().name,
                                mainImage: false,
                                mimeType: this.teaserImageData().file().type,
                                data: this.teaserImageData().base64String()
                            }
                        ])
                        .then(paths => {
                            this.widgetContent.teaserImageRef = paths[0];
                            this.widgetContent.teaserImageMimetype = this.teaserImageData().file().type;
                        })
                        .catch(err => console.error(err))
                        .finally(() => globalState.loading(false));
                });
            }

            return Promise.resolve();
        }
    }

    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }
}

ko.components.register("widget-news-edit", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetNewsEditViewModelParams) => new WidgetNewsEditViewModel(params),
    template: <string>require('./widget-news-edit.html')
});
