import * as ko from "knockout";
import * as common from "../_common";
import {observable} from "knockout-decorators";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import {campaignApi} from "../../../api/api-wrapper";
import {CampaignDto} from "../../../api/generated";
import '../../../components/elements/campaigns/campaign-list-item';

/**
 * Shows information about an campaign.
 */

/**
 * Display parameters for widget.
 */
interface WidgetCampaignTeaserViewModelParams extends common.WidgetComponentCompositionContext {

}

class WidgetCampaignTeaserViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentCampaignTeaser> {

    public campaign: KnockoutObservable<CampaignDto>;

    constructor(ctx: WidgetCampaignTeaserViewModelParams) {
        super(ctx);

        if (typeof this.widgetContent.campaignId === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "campaignId");
            (<common.WidgetContentCampaignTeaser>ctx.widget.content).campaignId = null;
        }

        this.campaign = asyncComputed(async () => {
            if (this.widgetContent.campaignId) {
                return campaignApi.getCampaign(this.widgetContent.campaignId)
                    .catch(err => {
                        console.error("widget campaign teaser could not be loaded", this.widgetContent.campaignId, err);
                        return null;
                    });

            } else {
                return Promise.resolve(null);
            }
        }, null);
    }

    public editComponent() {
        return "widget-campaign-teaser-edit";
    }
}

ko.components.register("widget-campaign-teaser", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetCampaignTeaserViewModelParams) => new WidgetCampaignTeaserViewModel(params),
    template: <string>require('./widget-campaign-teaser.html')
});


/**
 * Editor parameters for widget.
 */
interface WidgetCampaignTeaserEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model.
 */
class WidgetCampaignTeaserEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentCampaignTeaser> {

    @observable({expose: true, deep: true})
    public campaignId: number;

    constructor(ctx: WidgetCampaignTeaserEditViewModelParams) {
        super(ctx);
        this.campaignId = this.widgetContent.campaignId;

        ctx.callback.getResolveData = () => {
            this.widgetContent.campaignId = this.campaignId;
            return Promise.resolve();
        }
    }
}

ko.components.register("widget-campaign-teaser-edit", <KnockoutComponentTypes.Config>{
    viewModel: (params: WidgetCampaignTeaserEditViewModelParams) => new WidgetCampaignTeaserEditViewModel(params),
    template: <string>require('./widget-campaign-teaser-edit.html')
});
