import {WidgetDto} from "../../api/generated";
import {autobind} from "knockout-decorators";
import {EditorViewModelContext, loadWidget, saveWidget} from "./widget-utils";
import {Router} from "@profiscience/knockout-contrib-router";

/**
 * A page is the navigable component for showing a content page. It will load
 * the widget content from the API and pass it on to the component responsible
 * for showing the widget.
 *
 * Currently, the editors root component must be a PAGE.
 */
class ViewModel {
    public widget: WidgetDto;

    constructor(ctx: EditorViewModelContext) {
        if (ctx.widget) {
            this.widget = ctx.widget;
        } else {
            this.widget = {
                type: WidgetDto.TypeEnum.PAGE,
                content: {
                    layout: null
                },
                children: []
            };
        }

    }

    @autobind
    public saveWidget() {
        return saveWidget(this.widget).then(() => Router.update('editor'));
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./editor.html'),
    componentName: "editor",
    loader: (ctx: EditorViewModelContext) => {
        if (ctx.params && ctx.params.id) {

            return loadWidget(ctx.params.id).then(widget => {
                ctx.widget = widget;

                return Promise.resolve();
            });
        } else {

            return Promise.resolve();
        }
    }
};
